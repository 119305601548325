.app {
    background-color: rgba(253, 253, 253, 1);
}

.actions-header {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: end;
    margin-top: 5px;
    float: right;
}

.actions-selection-container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.start-new-investigation-icon {
    background-color: #fdfdfd;
    border-radius: 50%;
    border: none;
    box-shadow: 2px 1px 15px rgba(110, 128, 246, 0.11);
    padding-left: 9px;
    padding-top: 7px;
}

.more-actions-menu {
    background-color: #fdfdfd;
    border-radius: 50%;
    border: none;
    padding-left: 8px;
    padding-top: 3px;
}

.filter-container {
    min-height: 30px;
}

.filter-menu-icon {
    background: transparent;
    border: none;
}

.add-filter-selector,
.filter-selector {
    margin: 8px 12px 8px 0;
    padding: 10px;
    height: 30px;
    border: 0.8px solid #282a34;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add-filter-selector {
    background-color: #373b68;
    border: none;
    color: white;
}

.filter-selector svg path {
    fill: #282a34;
}

.add-filter-selector svg path {
    fill: white;
}

.filter-selector-open {
    background-color: #282a34;
    color: rgba(253, 253, 253, 1);
}

.filter-selector-open svg path {
    fill: #fdfdfd;
}

.rotated {
    transform: rotate(90deg);
    margin-left: 10px;
    color: #282a34;
}

.rotated-open {
    color: rgba(253, 253, 253, 1);
}

.filter-content {
    box-shadow: -4px 4px 4px rgba(21, 51, 108, 0.1);
    border-radius: 10px;
}

.filter-content .close-icon {
    border: none;
    background-color: transparent;
    width: 9px;
    height: 9px;
}

.filter-content {
    background-color: red;
}

.filter-content .search-input {
    width: 80%;
    border: none;
    border-bottom: 0.8px solid #f0f0f2;
}

.filter-content .clear-button {
    cursor: pointer;
    padding-top: 10px;
}

.close-icon {
    border: none;
    background-color: transparent;
    width: 9px;
    height: 9px;
    cursor: pointer;
}

.footer-container {
    display: flex;
    margin-top: 20px;
    width: 80%;
    flex-wrap: wrap;
}

.filter-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
}
