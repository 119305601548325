html,
body {
    background-color: #ffffff;
    font-family: 'commissioner', sans-serif;
    font-size: 12px;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

table .react-select__control {
    background-color: transparent !important;
    border-color: transparent !important;
}
table .react-select__single-value,
table .react-select__placeholder {
    color: #1c1c1c !important;
}
